exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-for-gps-js": () => import("./../../../src/pages/for-gps.js" /* webpackChunkName: "component---src-pages-for-gps-js" */),
  "component---src-pages-for-patients-js": () => import("./../../../src/pages/for-patients.js" /* webpackChunkName: "component---src-pages-for-patients-js" */),
  "component---src-pages-for-specialists-js": () => import("./../../../src/pages/for-specialists.js" /* webpackChunkName: "component---src-pages-for-specialists-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locations-act-js": () => import("./../../../src/pages/locations/act.js" /* webpackChunkName: "component---src-pages-locations-act-js" */),
  "component---src-pages-locations-js": () => import("./../../../src/pages/locations.js" /* webpackChunkName: "component---src-pages-locations-js" */),
  "component---src-pages-locations-nt-js": () => import("./../../../src/pages/locations/nt.js" /* webpackChunkName: "component---src-pages-locations-nt-js" */),
  "component---src-pages-locations-queensland-js": () => import("./../../../src/pages/locations/queensland.js" /* webpackChunkName: "component---src-pages-locations-queensland-js" */),
  "component---src-pages-locations-south-australia-js": () => import("./../../../src/pages/locations/south-australia.js" /* webpackChunkName: "component---src-pages-locations-south-australia-js" */),
  "component---src-pages-locations-tasmania-js": () => import("./../../../src/pages/locations/tasmania.js" /* webpackChunkName: "component---src-pages-locations-tasmania-js" */),
  "component---src-pages-locations-victoria-js": () => import("./../../../src/pages/locations/victoria.js" /* webpackChunkName: "component---src-pages-locations-victoria-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-adhd-testing-js": () => import("./../../../src/pages/services/adhd-testing.js" /* webpackChunkName: "component---src-pages-services-adhd-testing-js" */),
  "component---src-pages-services-allied-health-js": () => import("./../../../src/pages/services/allied-health.js" /* webpackChunkName: "component---src-pages-services-allied-health-js" */),
  "component---src-pages-services-general-psychiatry-js": () => import("./../../../src/pages/services/general-psychiatry.js" /* webpackChunkName: "component---src-pages-services-general-psychiatry-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

