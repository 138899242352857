export const menuItems = [
  {
    name: "/our-team",
    label: "About us",
    anchorDestination: "about-us",
    isAnchor: false,
  }, 
  {
    name: "/for-patients",
    label: "Our services",
    anchorDestination: "services",
    isAnchor: false,
  },
  {
    name: "/for-specialists",
    label: "Join us",
	  isAnchor: false,
  },
  {
    name: "/for-gps",
    label: "Refer to us (GPs)",
	  isAnchor: false,
  },
];
