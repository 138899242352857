import React, { useContext } from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import GlobalContext from "../../context/GlobalContext";

const Footer = () => {
  const gContext = useContext(GlobalContext);



  const contactClassName =
    gContext.footer.theme === "dark" ? "gr-text-color" : "text-primary";

  const linkClassName =
    gContext.footer.theme === "dark"
      ? "gr-text-color gr-hover-text-green"
      : "gr-text-color gr-hover-text-blue";

  const iconClassName =
    gContext.footer.theme === "dark"
      ? "gr-text-color"
      : "gr-text-color gr-hover-text-blue";

  const copyClassName =
    gContext.footer.theme === "dark"
      ? "gr-text-color-opacity"
      : "gr-text-color";

  return (
    <>
      <div
        className={`footer-section ${gContext.footer.theme === "dark"
          ? "bg-blackish-blue dark-mode-texts"
          : ""
          }`}
      >
        <Container>
          <div className="footer-top pt-12 py-lg-12">
            <Row>
              <Col xs="6" lg="3">
                <div className="single-footer mb-13 mb-lg-9">
                  <p className="footer-title gr-text-11 mb-3">Services</p>
                  <ul className="footer-list list-unstyled">
                  <li className="py-2">
                      <Link to={`/for-patients`} className={`gr-text-9 ${linkClassName}`}>
                        Services we offer
                      </Link>
                    </li>                    
                    <li className="py-2">
                      <AnchorLink to={`/#process`} className={`gr-text-9 ${linkClassName}`}>
                        How it works
                      </AnchorLink>
                    </li>
                    <li className="py-2">
                      <Link to={`/for-gps`} className={`gr-text-9 ${linkClassName}`}>
                        For referring GPs
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs="6" lg="3">
                <div className="single-footer mb-13 mb-lg-9">
                  <p className="footer-title gr-text-11 mb-3">Locations</p>
                  <ul className="footer-list list-unstyled">
                    <li className="py-2">
                    <Link to={`/locations/south-australia`} className={`gr-text-9 ${linkClassName}`}>
                        South Australia
                      </Link>
                    </li>
                    <li className="py-2">
                      <Link to={`/locations/victoria`} className={`gr-text-9 ${linkClassName}`}>
                        Victoria
                      </Link>
                    </li>
                    <li className="py-2">
                      <Link to={`/locations/queensland`} className={`gr-text-9 ${linkClassName}`}>
                        Queensland
                      </Link>
                    </li>
                    <li className="py-2">
                      <Link to={`/locations/tasmania`} className={`gr-text-9 ${linkClassName}`}>
                        Tasmania
                      </Link>
                    </li>
                    <li className="py-2">
                      <Link to={`/locations/nt`} className={`gr-text-9 ${linkClassName}`}>
                        NT
                      </Link>
                    </li>
                    <li className="py-2">
                      <Link to={`/locations/act`} className={`gr-text-9 ${linkClassName}`}>
                        ACT
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>                
              <Col xs="6" lg="3">
                <div className="single-footer mb-13 mb-lg-9">
                  <p className="footer-title gr-text-11 mb-3">Team</p>
                  <ul className="footer-list list-unstyled">
                    <li className="py-2">
                      <Link to={`/our-team`} className={`gr-text-9 ${linkClassName}`}>
                        About us
                      </Link>
                    </li>					
                    <li className="py-2">
                      <Link to={`/our-team#clinical`} className={`gr-text-9 ${linkClassName}`}>
                        Our team
                      </Link>
                    </li>			                    
                    <li className="py-2">
                      <Link to={`/for-specialists`} className={`gr-text-9 ${linkClassName}`}>
                        Join us
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs="6" lg="3">
                <div className="single-footer mb-13 mb-lg-9">
                  <p className="footer-title gr-text-11 mb-3">Legal</p>
                  <ul className="footer-list list-unstyled">
                    <li className="py-2">
                      <AnchorLink to={`/terms-of-use`} className={`gr-text-9 ${linkClassName}`}>
                        Terms of Use
                      </AnchorLink>
                    </li>
                    <li className="py-2">
                      <Link to={`/privacy-policy`} className={`gr-text-9 ${linkClassName}`}>
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
            
              <Col xs="9" lg="3">
                <div className="single-footer mb-13 mb-lg-9">
                  <p className="footer-title gr-text-11 mb-3">Contact us</p>
                  <ul className="footer-list list-unstyled">
                    <li className="py-1">
                      <a
                        className={`gr-text-8 font-weight-bold hover-underline active ${contactClassName}`}
                        href="mailto:reception@akkadianhealth.com"
                      >
                        reception@akkadianhealth.com
                      </a>
                    </li>
                    <li className="py-1">
                      PHONE: <a
                        className={`gr-text-8 font-weight-bold hover-underline active ${contactClassName}`}
                        href="tel:1800290880"
                      >
                        1800 290 880
                      </a>
                    </li>
                    <li className="py-1">
                      FAX: <a
                        className={`gr-text-11 font-weight-bold hover-underline active ${contactClassName}`}
                        href="fax:0851173206"
                      >
                        (08) 5117 3206
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
          <div className="copyright-area border-top py-9">
            <Row className="align-items-center">
              <Col lg="9">
                <p
                  className={`copyright-text gr-text-13 mb-6 text-center text-lg-left ${copyClassName}`}
                >
                  © Copyright 2024 Akkadian Pty. Ltd. trading as Akkadian Health. All Rights Reserved.
                </p>
              </Col>
              <Col lg="3" className="text-center text-lg-left">
                <p className={`copyright-text gr-text-13 mb-6 mb-lg-0 text-center text-lg-right ${copyClassName}`}>
                  ABN: 69 634 864 770
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Footer;
