import React from "react";
import { Link } from "gatsby";
import imgL1Logo from "../../assets/image/akkadian/Akkadian Health Logo-01.svg";
import imgL1LogoWhite from "../../assets/image/akkadian/Akkadian Health Logo-01.svg";
import styled from 'styled-components';

const LogoEl = styled.img`
  width: 104px;
`

const Logo = ({ white, height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`d-block ${className}`} {...rest}>
      {white ? (
        <LogoEl src={imgL1LogoWhite} alt="" />
      ) : (
          <LogoEl src={imgL1Logo} alt="" />
        )}
    </Link>
  );
};

export default Logo;
